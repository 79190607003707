* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.home-page {
  text-align: center;
  /* color: rgb(247, 148, 29); */
  color: rgb(248, 248, 248);
  margin: auto;
  padding: auto;
  background-image: url("../images/auto-care-backgroun.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;

  text-align: start;
}

.home-bg-container{
  background-image: url("../images/LANDFILL_clip.jpeg");

}

@keyframes scale-in {
  0% {
    background-size: 98%;
  }
  100% {
    background-size: 100%;
  }
}

@keyframes scale-out {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 98%;
  }
}

.overlay {
  z-index: 5;
  height: 100vh;
  width: 100%;
  /* background-color: rgba(165, 109, 40, 0.2); */
  background-color: rgba(19, 18, 18, 0.3);
  display: flex;
  justify-content: left;
  align-items: end;
}

.home-container {
  display: flex;
}

.inner-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  border-radius: 0 0 100% 0 / 200em;
  overflow: hidden;
  width: 60%;
  height: 100vh;
  background-image: linear-gradient(rgb(241, 225, 194), rgb(255, 153, 0));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: scale-in;
  animation-duration: 1s;
}
.poweredby {
  
  text-align: right;
  width: 38%;
  padding: 16;
 display: flex;
 flex-direction: column;
 align-items: flex-end;
}



.title-color {
  color: rgb(247, 148, 29);
}

.button-container {
  background-image: linear-gradient(orange, rgb(255, 153, 0));
  padding: 10px;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  text-size-adjust: 10px;
  border-color: transparent;
  cursor: pointer;
  text-decoration: none;
}

.button-container:hover {
  padding-right: 12px;
}

 .cancel {
  background-image: linear-gradient(rgb(255, 101, 12), rgb(255, 17, 0));
  
}


.heading1 {
  font-size: 62px;
}

.heading2 {
  font-size: 1.2em;
  color: white;
}

.heading3 {
  font-size: 18px;
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 999999999;
  background: var(--text-color);
  transform: translateX(100%);
  animation: shift-rightwards 1s ease-in-out infinite;
  animation-delay: .3s;
}

@keyframes shift-rightwards {
  0% {
      transform: translateX(-100%);
  }

  40% {
      transform: translateX(0%);
  }

  60% {
      transform: translateX(0%);
  }

  100% {
      transform: translateX(100%);
  }
}

/* Tablet view */
@media (max-width: 768px) {
  .hide-container {
    display: none;
  }
  .overlay {
    padding: 10px;
  }
  .inner-container {
    width: 100%;
    background: transparent;
  }
}

/* Mobile view */
@media (max-width: 480px) {
  .home-page {
    /* height: 50vh; */
    animation-duration: 0s;
  }
  .overlay {
    /* height: 50vh; */
    flex-direction: column;
  }
  .heading1 {
    font-size: 32px;
  }  

  .home-container {
    display: flex;
    flex-direction: column;
  }
}
